<script>
const formValues = () => ({
  departamento_id: '',
  plantilla_id: ''
})

const response = () => ({
  error: false,
  message: '',
  errors: {}
})

export default {
  name: 'ContratosProyectoDepartamentoView',
  data () {
    return {
      loading: false,
      departamento: null,
      modal: false,
      settingPlantilla: false,
      formValues: formValues(),
      response: response()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.loading = true
      const response = await this.$store.dispatch('contratosModule/getContrato', {
        proyecto_id: this.$route.params.proyecto,
        num_catastral: this.$route.params.departamento
      })
      this.departamento = response.data
      this.loading = false
      this.formValues.departamento_id = this.departamento.id
    },
    onShowModalPlantillaForm () {
      this.modal = true
    },
    async onGenerarFormato () {
      const result = await this.$store.dispatch('contratosModule/generarContrato', {
        proyecto_id: this.$route.params.proyecto,
        num_catastral: this.$route.params.departamento
      })

      await this.$onDownload(result, `contrato_departamento_${this.departamento.num_catastral}.docx`)

      this.$notify({ message: `Generación de contrato del departamento ${this.departamento.num_catastral}` })
    },
    async submitSelectPlantillaForm () {
      this.settingPlantilla = true
      this.response = response()
      const { error, message, data } = await this.$store.dispatch(
        'contratosModule/setPlantillaContrato', this.formValues
      )
      this.settingPlantilla = false
      this.response.error = error
      this.response.message = message
      this.response.errors = error ? data.errors : {}
      this.init()
    },
    resetSelectPlantillaForm () {
      document.activeElement.blur()
      this.$formulate.reset('selectPlantillaForm')
      this.formValues = formValues()
      this.response = response()
      this.modal = false
    }
  }
}
</script>

<template>
  <base-view title="Contratos" icon="file">
    <div>
      <b-modal
        v-model="modal"
        centered
        size="lg"
        scrollable
        dialog-class="x-modal-md"
      >
        <template #modal-title>Seleccionar plantilla de contratos</template>
        <template #default>
          <b-overlay :show="settingPlantilla">
            <template #overlay>
              <div class="text-center">
                <loading message="Asignando plantilla de contrato al departamento..."></loading>
              </div>
            </template>
            <FormulateForm
              ref="selectPlantillaForm"
              name="selectPlantillaForm"
              v-model="formValues"
              :errors="response.errors"
              @submit="submitSelectPlantillaForm"
            >
              <div class="card">
                <div class="card-header">
                  Seleccione la plantilla a asignar
                </div>
                <div class="card-body">
                  <b-row>
                    <b-col cols="12" md="12">
                      <FormulateInput
                        name="plantilla_id"
                        validation="bail|required">
                        <template #element>
                          <x-form-group title="Plantilla de contrato" required>
                            <x-select
                              v-model="formValues.plantilla_id"
                              dispatchPath="plantillasContratosModule/getResource"
                              getterListPath="plantillasContratosModule/getList"
                              :formatListWith="['id', 'identificacion']"
                            >
                            </x-select>
                          </x-form-group>
                        </template>
                      </FormulateInput>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </FormulateForm>
          </b-overlay>
        </template>
        <template #modal-footer>
          <!-- Enviar para crear o actualizar -->
          <div class="w-100">
            <x-form-footer-buttons
              :disabled="$refs.selectPeriodoForm ? $refs.selectPlantillaForm.hasErrors : false"
              :isEditing="true"
              :isCreating="false"
              @on-cancel="resetSelectPlantillaForm"
              @on-create="submitSelectPlantillaForm"
              @on-update="submitSelectPlantillaForm"
            ></x-form-footer-buttons>
          </div>
        </template>
      </b-modal>
      <check-authorization :requiresAuthorizations="['indice contratos']">
        <b-card no-body>
          <b-card-header>
            Departamento: {{ departamento ? departamento.num_catastral : '' }}
          </b-card-header>
          <b-card-body>
            <b-row class="mb-2">
              <b-col cols="12" md="6">
                <b-card
                  header="Información del departamento"
                  no-body
                  border-variant="success"
                  header-bg-variant="success"
                  header-text-variant="light"
                  class="mb-4"
                  style="height: 524px; overflow: auto;">
                  <loading v-if="loading"></loading>
                  <div v-else>
                    <ul>
                      <li>Inversionista: {{ departamento ? departamento.inversionista.identificacion : '' }}</li>
                      <li>Número catastral: {{ departamento ? departamento.num_catastral : '' }}</li>
                      <li>Cuenta predial: {{ departamento ? departamento.cuenta_predial : '' }}</li>
                    </ul>
                  </div>
                </b-card>
              </b-col>
              <b-col cols="12" md="6">
                <b-card
                  header="Plantilla de contrato"
                  no-body
                  border-variant="primary"
                  header-bg-variant="primary"
                  header-text-variant="light"
                  class="mb-4"
                  style="height: 524px; overflow: auto;"
                >
                  <loading v-if="loading"></loading>
                  <div v-else>
                    <div v-if="departamento && departamento.contrato.plantilla">
                      {{ departamento ? departamento.contrato.plantilla.identificacion : '' }}
                      <b-button @click="onGenerarFormato" size="sm">Generar formato</b-button>
                    </div>
                    <div v-else>
                      No se ha asignado ninguna plantilla a este departamento
                      <b-button @click="onShowModalPlantillaForm" size="sm">Asignar plantilla</b-button>
                    </div>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </check-authorization>
    </div>
  </base-view>
</template>

<style scoped>

</style>
